import { Link } from 'gatsby'
import React from 'react'
import { cn, buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import { StaticImage } from 'gatsby-plugin-image'
import BlockText from './block-text'
import styled from 'styled-components'
import { typography, Heading3 } from '../styles/Typography'
import SVGWave from '../components/wave-svg'

import { responsiveTitle3 } from './typography.module.css'

const RecipePreviewLink = styled(Link)`
  transition: transform 200ms ease;
      &:hover {
        transform: scale(1.03);
        transition: transform 200ms ease;
      }
  display: block;
  color: inherit;
  text-decoration: none;
  box-shadow: 0.2rem 0.2rem 0.5rem #e1e1e1;
  border: solid 1px #e1e1e1;
  min-height: 100%;
  border-radius: 3rem 3rem;
`

const LeadMediaThumb = styled.div`
  position: relative;
  padding-bottom: 66.666%;

  svg {
    position: absolute;
    z-index: 10;
    bottom: -1px;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3rem 3rem 0 0;
  }
`
const RecipeDescription = styled.div`
  padding: 0 2rem 1rem;
  display: flex;
  align-items: flex-end;
`

const StyledExcerpt = styled.div`
  & p {
    margin: 0.5em 0;
  }

  & strong {
    font-weight: 600;
  }
`

const Title = styled(Heading3)`
  font-family: ${typography.handwritten};
  font-size: 2rem;
  font-weight: bold;
    @media (hover: hover) {
      .root:hover & {
        font-size: 10rem;
        font-weight: bold;
      }
    }
`

function RecipePreview (props) {
  return (
    <RecipePreviewLink to={`/recipe/${props.slug.current}`}>
      <LeadMediaThumb>
        <SVGWave />
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </LeadMediaThumb>
      <RecipeDescription>
        <Title>{props.title}</Title>
        {/* {props._rawExcerpt && (
          <StyledExcerpt>
            <BlockText blocks={props._rawExcerpt} />
          </StyledExcerpt>
        )} */}
      </RecipeDescription>
    </RecipePreviewLink>
  )
}

export default RecipePreview
