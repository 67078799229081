import React, { useRef, useEffect } from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { device } from '../styles/MediaQueries'
import { colors } from '../styles/Variables'
import { typography, Heading1, Heading3, Paragraph } from '../styles/Typography'
import { AnimAboutSections } from '../components/animations'

const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: 3em;
    @media ${device.mediaMinLarge} {
      margin-top: 8em;
    }
    @media ${device.mediaMinXLarge} {
      margin-top: 3em;
    }
    
    @media ${device.mediaMinLarge} {
      flex-direction: row;
      
    }
    &.column-reverse-on-small-screen {
        @media ${device.mediaMaxLarge} {
            flex-direction: column-reverse;
        }
    }
`

const Column = styled.div`
    width: 100%;

    @media ${device.mediaMinLarge} {
      width: 50%;
    }

    &.text-column {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      padding: 0 3rem;
      @media ${device.mediaMaxMedium} {
          margin: 0 0 3rem 0;
          padding: 0;
      }
    }

    .about-image {
      max-width: 450px;
      margin: 0 auto;
      border: solid black 2px;
        @media ${device.mediaMaxMedium} {
            max-width: 350px;
            margin: 0 auto;
        }
    }
`

const ImgWrapper = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
`

const StyledHeading1 = styled(Heading1)`
    font-size: calc(44 / 17 * 1.25rem);
`
const AboutWrapper = styled.div`
    border-bottom: dotted 4px #ccc;
    padding-bottom: 60px;

    .lineParent {
      overflow: hidden;
    }
`

function AboutLanding (props) {
  const sectionRefs = useRef([])
  const addToTextRefs = useRef([])

  const addToSectionRefs = (el) => {
    if (el && !sectionRefs.current.includes(el)) {
      sectionRefs.current.push(el)
    }
  }

  const sectionItems = sectionRefs.current

  useEffect(() => {
    sectionItems.forEach((section, index) => {
      AnimAboutSections(section, index)
    })
  }, [])

  return (
    <AboutWrapper>
      <SectionWrapper ref={addToSectionRefs}>
        <Column
          className='text-column'
        >
          <StyledHeading1>Hi, I'm Emily!</StyledHeading1>
          <Paragraph ref={addToTextRefs} className='about-text'>
            I am dual registered as both a Registered Nurse and Registered Associate Nutritionist (BSc MSc ANutr RN). I hold a first-class BSc degree in Adult Nursing and an Msc degree in Clinical Nutrition and Public Health from University College London. I have a great passion for all things food, nutrition and women’s health and would love to share that passion with you. You will not find any fad diets on my site, but you will find a regular stream of nutritious recipes and blog articles where you will be able find tips and evidence-based information around women’s health, fertility and nutrition.
          </Paragraph>
        </Column>
        <Column>
          <ImgWrapper className='img-wrapper'>
            <StaticImage
              src='../assets/images/about-image00001.jpeg'
              alt='Emily the Nutri-Nurse'
              placeholder='blurred'
              quality='100'
              layout='fullWidth'
              aspectRatio={3 / 4}
              className='about-image'
            />
          </ImgWrapper>
        </Column>
      </SectionWrapper>
      <SectionWrapper ref={addToSectionRefs} className='column-reverse-on-small-screen'>
        <Column>
          <ImgWrapper className='img-wrapper'>
            <StaticImage
              src='../assets/images/about-image00002.jpeg'
              alt='Em the Nutri Nurse'
              placeholder='blurred'
              quality='100'
              layout='fullWidth'
              aspectRatio={3 / 4}
              className='about-image'
            />
          </ImgWrapper>
        </Column>
        <Column
          className='text-column'
        >
          <Paragraph ref={addToTextRefs} className='about-text'>
            I am an NHS trained and qualified adult nurse with experience in working throughout the NHS and private settings in areas such as surgical assessment, trauma, orthopaedic and gynaecology. I now work as a Fertility Nurse and Patient Liaison for Hertility who are on a mission to make information about female health accessible and affordable for everyone to empower and equip them with knowledge of their reproductive health.
          </Paragraph>
        </Column>

      </SectionWrapper>
      <SectionWrapper ref={addToSectionRefs}>
        <Column
          className='text-column'
        >
          <Paragraph ref={addToTextRefs} className='about-text'>
            Since working as a nutritionist, I have been the lead Nutritionist for @strng app, whereby I designed and nutritionally analysed performance meals to fit within individual nutritional goals. I have studied further MSc modules in sports nutrition as well as frequent and ongoing studying into fertility, pre- and post-natal and infant nutrition and I have a great interest in particular into optimising nutrition for both females and males throughout the perinatal and pre-natal period.
          </Paragraph>
        </Column>
        <Column>
          <ImgWrapper className='img-wrapper'>
            <StaticImage
              src='../assets/images/about-image00003.jpeg'
              alt='Em the Nutri Nurse'
              placeholder='blurred'
              quality='100'
              layout='fullWidth'
              aspectRatio={3 / 4}
              className='about-image'
              imgClassName='about-image-img'
            />
          </ImgWrapper>
        </Column>
      </SectionWrapper>

    </AboutWrapper>
  )
}

export default AboutLanding
