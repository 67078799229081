import { Link } from 'gatsby'
import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import styled from 'styled-components'

import RecipePreview from './recipe-preview'

import { device } from '../styles/MediaQueries'
import { colors } from '../styles/Variables'
import { typography } from '../styles/Typography'

gsap.registerPlugin(ScrollTrigger, SplitText)

const RecipePreviewWrapper = styled.div` 
  margin: 2rem 0 4rem;
`

const Headline = styled.h2` 
  ${typography.responsiveTitle3}
  margin: 2rem 0;

  & a {
    color: inherit;
    text-decoration: none;
  }
`

const Grid = styled.ul` 
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(320px, 1fr) );
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
`

const BrowseMoreLink = styled.div` 
  ${typography.base}
  margin-top: 1rem;
  text-align: right;

  & a {
    display: inline-block;
    padding: 0.5rem 0;
    color: inherit;
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        font-weight: bold;
      }
    }
  }
`

const RecipeListItem = styled.li`
  visibility: hidden;
`

function RecipePreviewGrid (props) {
  const revealRefs = useRef([])
  revealRefs.current = []

  // Where did I get this code from? iHateTomatoes somehow.
  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  const recipeItems = revealRefs.current

  useEffect(() => {
    gsap.set(recipeItems, { y: 100, visibility: 'hidden' })
    setTimeout(() => {
      ScrollTrigger.batch(recipeItems, {
        start: 'top 85%',
        onEnter: batch => gsap.to(batch, { ease: 'back', autoAlpha: 1, y: 0, stagger: { each: 0.075, grid: [1, 3] }, overwrite: true })
      })
    }, 450)
  }, [])

  return (
    <RecipePreviewWrapper>
      {props.title && (
        <Headline>
          {props.browseMoreHref ? (
            <Link to={props.browseMoreHref}>{props.title}</Link>
          ) : (
            props.title
          )}
        </Headline>
      )}
      <Grid>
        {props.nodes &&
          props.nodes.map(node => (
            <RecipeListItem key={node.id} ref={addToRefs}>
              <RecipePreview {...node} />
            </RecipeListItem>
          ))}
      </Grid>
      {props.browseMoreHref && (
        <BrowseMoreLink>
          <Link to={props.browseMoreHref}>Browse more recipes</Link>
        </BrowseMoreLink>
      )}
    </RecipePreviewWrapper>
  )
}

RecipePreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default RecipePreviewGrid
