import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '../components/block-content'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero-landing'
import AboutCols from '../components/about-two-cols'
import RecipePreviewGrid from '../components/recipe-preview-grid'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

export const query = graphql`
  query AboutPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      _rawBody
    }
    people: allSanityPerson {
      edges {
        node {
          id
          image {
            asset {
              _id
            }
          }
          name
          _rawBio
        }
      }
    }
    desktop: file(relativePath: { eq: "about-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
    recipes: allSanityRecipe(limit: 12, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const AboutPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page
  const personNodes =
    data && data.people && mapEdgesToNodes(data.people).filter(filterOutDocsWithoutSlugs)
  const backgroundImageData = data && data.desktop.childImageSharp.fluid
  const recipeNodes = (data || {}).recipes
    ? mapEdgesToNodes(data.recipes).filter(filterOutDocsWithoutSlugs)
    : []

  if (!page) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }

  return (
    <>
      <SEO title={page.title} />
      {/* <Hero imageData={backgroundImageData}>{page.title}</Hero> */}
      <Container>
        <AboutCols />
        {recipeNodes && (
          <RecipePreviewGrid
            title='Latest recipes'
            nodes={recipeNodes}
            browseMoreHref='/recipes/'
          />
        )}
        {/* <BlockContent blocks={page._rawBody || []} />
        {personNodes && personNodes.length > 0 && <PeopleGrid items={personNodes} title='People' />} */}
      </Container>
    </>
  )
}

export default AboutPage
